import React from "react"
import SEO from "../components/seo"
import { motion } from "framer-motion"
import Img from "gatsby-image"
import { useStaticQuery } from "gatsby"
import { FaCheck } from "react-icons/fa"
import { RiComputerLine } from "react-icons/ri"
import { VscMail } from "react-icons/vsc"

const duration = 0.35

const container = {
  visible: {
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.2,
      delayChildren: duration,
    },
  },
}
const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexQuery {
      hero: file(relativePath: { eq: "hero.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      roundworks: file(relativePath: { eq: "roundworks.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      roundworksLogo: file(relativePath: { eq: "roundworks-logo.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      roundworksStars: file(relativePath: { eq: "roundworks-stars.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO title="Home" />
      <motion.section variants={container} initial="hidden" animate="visible">
        {/* Hero */}
        <motion.div
          className="text-white content bg-secondary-default"
          variants={item}
          transition="easeInOut"
        >
          <div className="container">
            <div className="px-8 lg:flex lg:flex-row">
              <div className="flex lg:w-1/2">
                <div className="flex flex-col my-auto">
                  <h1 className="text-3xl md:text-5xl xl:text-8xl font-display">
                    Discover simple email and IT solutions.
                  </h1>
                  <p className="text-lg font-light md:text-2xl">
                    Adtrak have partnered with RoundWorks to offer simple email
                    and IT solutions to our clients across the country.
                  </p>
                </div>
              </div>
              <Img
                fluid={data.hero.childImageSharp.fluid}
                className="block w-full max-w-screen-sm mx-auto opacity-100 lg:w-1/2 lg:max-w-screen-lg lg:-mt-16 lg:pb-16"
              />
            </div>
          </div>
        </motion.div>
        {/* End Hero */}

        {/* RoundWorks intro */}
        <motion.div
          className="text-secondary-dark content bg-warm-gray"
          variants={item}
          transition="easeInOut"
        >
          <div className="container pt-16">
            <div className="px-8 xl:flex xl:flex-row xl:space-x-40">
              <div className="flex xl:w-2/5">
                <div className="sticky top-0 flex flex-col py-16 mb-auto">
                  <h2 className="text-3xl md:text-4xl font-display">
                    Who are RoundWorks?
                  </h2>
                  <p className="text-2xl font-light">
                    We’re a modern, cost-effective and honest Managed IT Support
                    company based in the heart of the East Midlands.{" "}
                  </p>
                  <p className="text-xl font-light">
                    Our principles of delivering honest advice and fairly-priced
                    IT services with a philosophy, “treat others how you want to
                    be treated” remain true to this day.
                  </p>

                  <p className="text-xl font-light">
                    It’s a winning combination for our clients and one that has
                    rewarded us consistently with 5 star rated reviews. A
                    service no other IT support company can match.
                  </p>
                </div>
              </div>
              <div className="flex flex-col w-full xl:w-3/5">
                <div className="flex flex-col w-full space-y-2 lg:pt-8 lg:flex-row lg:flex-wrap 2xl:space-y-0 lg:flex-col 2xl:flex-row 2xl:space-x-2 2xl:flex-nowrap">
                  <a
                    className="flex flex-col items-center justify-center w-full p-4 space-x-4 bg-white hover:bg-grey-default md:flex-row 2xl:justify-between 2xl:w-1/2"
                    href="https://www.roundworks-it.co.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Img
                      fixed={data.roundworksLogo.childImageSharp.fixed}
                      className="block w-full h-auto"
                    />
                    <span>View Site &rsaquo;</span>
                  </a>
                  <a
                    className="flex flex-col items-center justify-center w-full p-4 space-x-4 bg-white hover:bg-grey-default md:flex-row 2xl:justify-between 2xl:w-1/2"
                    href="https://www.google.com/search?q=roundworks+it&oq=roundworks+it&aqs=chrome.0.0i355j46i175i199j69i60l3.3592j0j4&sourceid=chrome&ie=UTF-8#lrd=0x4879c1c5c33e03c3:0x9d8eec319e3effff,1,,,"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Img
                      fixed={data.roundworksStars.childImageSharp.fixed}
                      className="block w-full h-auto"
                    />
                    <span>View Reviews &rsaquo;</span>
                  </a>
                </div>
                <Img
                  fluid={data.roundworks.childImageSharp.fluid}
                  className="block w-full max-w-screen-md mx-auto mt-auto opacity-100"
                />
              </div>
            </div>
          </div>
        </motion.div>
        {/* End RoundWorks intro */}

        {/* How can RoundWorks help? */}
        <motion.div
          className="text-center border-b text-secondary-dark content border-grey-default"
          variants={item}
          transition="easeInOut"
        >
          <div className="container">
            <div className="px-8 py-32">
              <h2 className="text-3xl md:text-4xl font-display">
                How Can RoundWorks Help My Business?
              </h2>
              <ul className="inline-flex flex-col w-auto mx-auto mb-8 text-lg font-light text-left md:flex-row md:space-x-4 lg:space-x-12 text-secondary-default">
                <li className="relative pl-8">
                  <FaCheck className="absolute top-0 left-0 mt-1" /> 15 minute
                  response time
                </li>
                <li className="relative pl-8">
                  <FaCheck className="absolute top-0 left-0 mt-1" /> 30 day
                  rolling contracts
                </li>
                <li className="relative pl-8">
                  <FaCheck className="absolute top-0 left-0 mt-1" /> Dedicated
                  account manager
                </li>
                <li className="relative pl-8">
                  <FaCheck className="absolute top-0 left-0 mt-1" /> Inclusive
                  IT audits
                </li>
              </ul>
              <div className="flex flex-col justify-center w-full max-w-screen-lg mx-auto my-16 space-y-4 md:space-y-0 md:flex-row md:space-x-16 lg:space-x-28">
                <div className="flex flex-col flex-1 p-12 bg-white shadow-custom">
                  <VscMail className="mx-auto text-8xl text-primary-default" />
                  <h3 className="mt-2 text-3xl text-primary-default font-display">
                    Email Solutions
                  </h3>
                  <ul className="inline-flex flex-col w-auto mx-auto mb-8 space-y-2 text-sm font-light text-center text-secondary-dark">
                    <li className="relative">
                      <FaCheck className="inline mr-2 -mt-1 text-sm" />
                      <span className="text-lg">
                        Office 365 setup and management
                      </span>
                    </li>
                    <li className="relative">
                      <FaCheck className="inline mr-2 -mt-1 text-sm" />
                      <span className="text-lg">
                        Google Workspace setup and management
                      </span>
                    </li>
                    <li className="relative">
                      <FaCheck className="inline mr-2 -mt-1 text-sm" />
                      <span className="text-lg"> Email migration</span>
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col flex-1 p-12 bg-white shadow-custom">
                  <RiComputerLine className="mx-auto text-8xl text-primary-default" />
                  <h3 className="mt-2 text-3xl text-primary-default font-display">
                    IT Solutions
                  </h3>
                  <ul className="inline-flex flex-col w-auto mx-auto mb-8 space-y-2 text-sm font-light text-center text-secondary-dark">
                    <li className="relative">
                      <FaCheck className="inline mr-2 -mt-1 text-sm" />
                      <span className="text-lg">
                        Computer troubleshooting
                      </span>
                    </li>
                    <li className="relative">
                      <FaCheck className="inline mr-2 -mt-1 text-sm" />
                      <span className="text-lg">Networking</span>
                    </li>
                    <li className="relative">
                      <FaCheck className="inline mr-2 -mt-1 text-sm" />
                      <span className="text-lg">IT Support</span>
                    </li>
                    <li className="relative">
                      <FaCheck className="inline mr-2 -mt-1 text-sm" />
                      <span className="text-lg">Outlook, Word & Excel</span>
                    </li>
                  </ul>
                </div>
              </div>

              <h3 className="text-xl md:text-3xl font-display">
                Also available...
              </h3>
              <ul className="inline-flex flex-col items-center w-auto mx-auto text-xl font-light text-left md:flex-row md:space-x-4 text-secondary-default">
                <li className="relative">Broadband Solutions</li>
                <li className="text-4xl text-primary-default">&bull;</li>
                <li className="relative">IT and Cyber Security</li>
                <li className="text-4xl text-primary-default">&bull;</li>
                <li className="relative">Small Business Hosting</li>
              </ul>
            </div>
          </div>
        </motion.div>
        {/* End How can RoundWorks help? */}

        {/* What does it cost my business? */}
        <motion.div
          className="text-left text-secondary-dark content"
          variants={item}
          transition="easeInOut"
        >
          <div className="container">
            <div className="px-8 py-28">
              <h2 className="text-3xl text-center md:text-4xl font-display">
                What Does it Cost my Business?
              </h2>
              <div className="flex flex-col w-full max-w-screen-xl mx-auto mt-16 space-y-6 md:space-y-0 md:flex-row md:space-x-16">
                <div className="w-full">
                  <h3 className="text-xl md:text-2xl font-display">
                    Managed email hosting & support
                  </h3>
                  <p className="max-w-md text-xl font-light">
                    Unlimited Email Support between the hours of 7 am-7 pm
                    Monday to Friday specifically in relation to Email related
                    issues.
                  </p>
                  <table className="w-full mt-8 text-sm md:text-base lg:text-lg">
                    <tr className="font-semibold border-b-2 border-grey-default font-display">
                      <th className="p-4 border-r text-primary-default border-grey-default">Mailboxes</th>
                      <th className="p-4 text-primary-default">Price</th>
                    </tr>
                    <tr className="border-b border-grey-default">
                      <td className="p-4 border-r border-grey-default">1 - 5</td>
                      <td className="p-4">
                        <em className="text-xs text-primary-light">from</em>{" "}
                        £40pcm
                      </td>
                    </tr>
                    <tr className="border-b border-grey-default bg-grey-light">
                      <td className="p-4 border-r border-grey-default">6 - 10</td>
                      <td className="p-4">
                        <em className="text-xs text-primary-light">from</em>{" "}
                        £60pcm
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="w-full">
                  <h3 className="text-xl md:text-2xl font-display">
                    Email licensing
                  </h3>
                  <p className="max-w-md text-xl font-light">
                    There are a variety of options to suit your business setup
                    which Roundworks will be able to help you choose. Here are
                    some examples.
                  </p>
                  <table className="w-full mt-8 text-sm md:text-base lg:text-lg">
                    <tr className="font-semibold border-b-2 border-grey-default font-display">
                      <th className="p-4 border-r text-primary-default border-grey-default">Provider</th>
                      <th className="p-4 border-r text-primary-default border-grey-default">1 mailbox</th>
                      <th className="p-4 text-primary-default">5 mailboxes</th>
                    </tr>
                    <tr className="border-b border-grey-default">
                      <td className="p-4 border-r border-grey-default">Exchange Online</td>
                      <td className="p-4 border-r border-grey-default">
                        <em className="text-xs text-primary-light">from</em>{" "}
                        £3.30pcm
                      </td>
                      <td className="p-4">
                        <em className="text-xs text-primary-light">from</em>{" "}
                        £16.25pcm
                      </td>
                    </tr>
                    <tr className="border-b border-grey-default bg-grey-light">
                      <td className="p-4 border-r border-grey-default ">Office 365 Business Basic</td>
                      <td className="p-4 border-r border-grey-default">
                        <em className="text-xs text-primary-light">from</em>{" "}
                        £4.90pcm
                      </td>
                      <td className="p-4">
                        <em className="text-xs text-primary-light">from</em>{" "}
                        £20.85pcm
                      </td>
                    </tr>
                    <tr className="border-b border-grey-default">
                      <td className="p-4 border-r border-grey-default">Office 365 Business Standard</td>
                      <td className="p-4 border-r border-grey-default">
                        <em className="text-xs text-primary-light">from</em>{" "}
                        £10.30pcm
                      </td>
                      <td className="p-4">
                        <em className="text-xs text-primary-light">from</em>{" "}
                        £50.00pcm
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
        {/* End What does it cost my business? */}

        {/* Sign up */}
        <motion.div
          className="text-left bg-white text-secondary-dark"
          variants={item}
          transition="easeInOut"
        >
          <div className="text-white bg-secondary-dark">
            <div className="container">
              <div className="px-8 py-20 text-center">
                <h2 className="text-3xl md:text-4xl font-display">
                  Not Sure What You Need?
                </h2>
                <p className="mx-auto text-lg font-light text-secondary-light">
                  No problem. Roundworks will be able to advise you on the best
                  system for your business.
                </p>
              </div>
            </div>
          </div>
        </motion.div>
        <motion.div
          className="text-left bg-white text-secondary-dark content"
          variants={item}
          transition="easeInOut"
        >
          <div className="px-8 py-20 text-white bg-secondary-default">
            <div className="mx-auto">
              <h2 className="px-8 mb-16 text-3xl text-center md:text-4xl font-display text-secondary-light">
                Great! How Do I Sign Up?
              </h2>
              <form
                id="form"
                action="https://formspree.io/f/meqpwldb"
                method="POST"
                className="flex flex-col max-w-sm mx-auto space-y-6"
              >
                <label className="w-full p-1">
                  <span className="block mb-4 text-lg font-semibold font-display">Name*</span>
                  <input
                    required
                    type="text"
                    className="w-full p-4 text-black"
                    name="name"
                    placeholder="e.g. John Smith"
                  />
                </label>
                <label className="w-full p-1">
                  <span className="block mb-4 text-lg font-semibold font-display">Company*</span>
                  <input
                    required
                    type="text"
                    name="company"
                    placeholder="e.g. My Company Name Ltd"
                    className="w-full p-4 text-black"
                  />
                </label>
                <label className="w-full p-1">
                  <span className="block mb-4 text-lg font-semibold font-display">Email*</span>
                  <input
                    required
                    type="email"
                    name="email"
                    placeholder="e.g. me@mycompany.com"
                    className="w-full p-4 text-black"
                  />
                </label>
                <label className="w-full p-1">
                  <span className="block mb-4 text-lg font-semibold font-display">Telephone*</span>
                  <input
                    required
                    type="tel"
                    name="telephone"
                    placeholder="e.g. 07700 123 456"
                    className="w-full p-4 text-black"
                  />
                </label>
                <label className="w-full p-1">
                  <span className="block mb-1 text-lg font-semibold font-display">Your Contact Name / Email at Adtrak*</span>
                  <span className="block mb-4 text-sm font-semibold font-display">Who has advised you to speak to RoundWorks?</span>
                  <input
                    required
                    type="text"
                    name="adtrak_contact"
                    placeholder="e.g. Jane Doe, jane.doe@adtrak.co.uk"
                    className="w-full p-4 text-black"
                  />
                </label>
                <label className="w-full p-1">
                  <span className="block mb-4 text-lg font-semibold font-display">Your Message*</span>
                  <textarea
                    required
                    placeholder="How can we help?"
                    name="message"
                    className="w-full h-32 p-4 text-black"
                  ></textarea>
                </label>
                <input type="text" name="_gotcha" className="hidden" />
                <div className="w-full p-1 text-center">
                  <input
                    type="submit"
                    className="p-4 text-lg text-white transition duration-300 cursor-pointer bg-primary-default hover:text-white hover:bg-primary-dark"
                    value="Send Enquiry"
                  />
                </div>
              </form>
            </div>
          </div>
        </motion.div>
        {/* End Sign up? */}
      </motion.section>
    </>
  )
}

export default IndexPage